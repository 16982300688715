import React from "react";
import staticText from "../../productData.js";
import { Container, Table } from "react-bootstrap";

const MyTable = ({ language, tableData }) => {
  const {
    dimensionsText,
    minOrderTex,
    infoMaterialText,
    infoOverprintText,
    additionalOptionText,
    additionalInfoText,
    leadTimeText,
    infoAboutShippingText,
    priceShippingText
  } = staticText[language];
  const {
    dimensions,
    minimum_order_quantity,
    material_information,
    print_information,
    additional_options,
    additional_information,
    lead_time,
    information_about_shipping_and_logistic,
    shipping_price
  } = tableData;
  return (
    <Container>
      <Table bordered>
        <tbody>
          {dimensions && (
            <tr>
              <td>{dimensionsText}</td>
              <td>{dimensions}</td>
            </tr>
          )}
          {minimum_order_quantity && (
            <tr>
              <td>{minOrderTex}</td>
              <td>{minimum_order_quantity}</td>
            </tr>
          )}
          {material_information && (
            <tr>
              <td>{infoMaterialText}</td>
              <td>{material_information}</td>
            </tr>
          )}
          {print_information && (
            <tr>
              <td>{infoOverprintText}</td>
              <td>{print_information}</td>
            </tr>
          )}
          {/* <tr>
                        <td>{infoAboutFinishingText}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>{infoAboutPunchText}</td>
                        <td></td>
                    </tr> */}
          {additional_options && (
            <tr>
              <td>{additionalOptionText}</td>
              <td>{additional_options}</td>
            </tr>
          )}
          {additional_information && (
            <tr>
              <td>{additionalInfoText}</td>
              <td>{additional_information}</td>
            </tr>
          )}
          {/* <tr>
                        <td>{patternText}</td>
                        <td></td>
                    </tr> */}
          {lead_time && (
            <tr>
              <td>{leadTimeText}</td>
              <td>{lead_time}</td>
            </tr>
          )}
          {information_about_shipping_and_logistic && (
            <tr>
              <td>{infoAboutShippingText}</td>
              <td>{information_about_shipping_and_logistic}</td>
            </tr>
          )}
          {shipping_price && (
            <tr>
              <td>{priceShippingText}</td>
              <td>{shipping_price}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default MyTable;
