export default {
  en: {
    productName: "Products",
    homeName: "Home page",
    productSpecificationText: "Product specification",
    dimensionsText: "DIMENSION",
    minOrderTex: "MINIMUM ORDER QUANTITY",
    infoMaterialText: "MATERIAL INFORMATION",
    infoOverprintText: "OVERPRINT INFORMATION",
    infoAboutFinishingText: "FINISHING INFORMATION",
    infoAboutPunchText: "PUNCH INFORMATION",
    additionalOptionText: "ADDITIONAL OPTIONS",
    additionalInfoText: "ADDITIONAL INFORMATION",
    patternText: "PATTERN",
    leadTimeText: "LEAD TIME",
    infoAboutShippingText: "LOGISTICS",
    priceShippingText: "SHIPPING COST",
    sizeText: "Size",
    with_coverText: "Included with the cover",
    yesOrNoText: ["Yes", "No"],
    amountText: "Amount",
    price_per_itemText: "Price per item",
    discountText: "Discount",
    sumText: "Sum",
    send_questionText: "Send Question",
    receive_pdfText: "PDF sketch",
    bigDescriptionOneProductMultipleChoices: "one product many possibilities",
    sendToUsYourLogo: "Send to us your logo",
    sendToUsSmallTextPart1: "WE WILL MAKE AN EXCELLENT",
    sendToUsSmallTextPart2: "FREE PROJECT FOR YOU"
  },
  dk: {
    productName: "Produkter",
    homeName: "Home",
    productSpecificationText: "Product specification",
    dimensionsText: "DIMENSIONER",
    minOrderTex: "MINIMUMSBESTILLING",
    infoMaterialText: "MATERIALINFORMATION",
    infoOverprintText: "PRINTINFORMATION",
    infoAboutFinishingText: "FINISHING INFORMATION",
    infoAboutPunchText: "PUNCH INFORMATION",
    additionalOptionText: "TILLÆGSMULIGHEDER",
    additionalInfoText: "YDERLIGERE INFORMATIONER",
    patternText: "PATTERN",
    leadTimeText: "LEVERINGSTID",
    infoAboutShippingText: "OPLYSNINGER OM LOGISTIK",
    priceShippingText: "FRAGTPRIS",
    sizeText: "Size",
    with_coverText: "Included with the cover",
    yesOrNoText: ["Yes", "No"],
    amountText: "Amount",
    price_per_itemText: "Price per item",
    discountText: "Discount",
    sumText: "Sum",
    send_questionText: "Send Question",
    receive_pdfText: "PDF skitse",
    bigDescriptionOneProductMultipleChoices: "et produkt mange muligheder",
    sendToUsYourLogo: "Send dit logo til os",
    sendToUsSmallTextPart1: "Vi vil lave et fremragende",
    sendToUsSmallTextPart2: "GRATIS projekt til dig"
  },
  es: {
    productName: "Products",
    homeName: "Home page",
    productSpecificationText: "Product specification",
    dimensionsText: "DIMENSION",
    minOrderTex: "MINIMUM ORDER QUANTITY",
    infoMaterialText: "MATERIAL INFORMATION",
    infoOverprintText: "OVERPRINT INFORMATION",
    infoAboutFinishingText: "FINISHING INFORMATION",
    infoAboutPunchText: "PUNCH INFORMATION",
    additionalOptionText: "ADDITIONAL OPTIONS",
    additionalInfoText: "ADDITIONAL INFORMATION",
    patternText: "PATTERN",
    leadTimeText: "LEAD TIME",
    infoAboutShippingText: "LOGISTICS",
    priceShippingText: "SHIPPING COST",
    sizeText: "Size",
    with_coverText: "Included with the cover",
    yesOrNoText: ["Yes", "No"],
    amountText: "Amount",
    price_per_itemText: "Price per item",
    discountText: "Discount",
    sumText: "Sum",
    send_questionText: "Send Question",
    receive_pdfText: "PDF sketch",
    bigDescriptionOneProductMultipleChoices: "one product many possibilities",
    sendToUsYourLogo: "Send to us your logo",
    sendToUsSmallTextPart1: "WE WILL MAKE AN EXCELLENT",
    sendToUsSmallTextPart2: "FREE PROJECT FOR YOU"
  },
  de: {
    productName: "Products",
    homeName: "Home page",
    productSpecificationText: "Product specification",
    dimensionsText: "DIMENSION",
    minOrderTex: "MINIMUM ORDER QUANTITY",
    infoMaterialText: "MATERIAL INFORMATION",
    infoOverprintText: "OVERPRINT INFORMATION",
    infoAboutFinishingText: "FINISHING INFORMATION",
    infoAboutPunchText: "PUNCH INFORMATION",
    additionalOptionText: "ADDITIONAL OPTIONS",
    additionalInfoText: "ADDITIONAL INFORMATION",
    patternText: "PATTERN",
    leadTimeText: "LEAD TIME",
    infoAboutShippingText: "LOGISTICS",
    priceShippingText: "SHIPPING COST",
    sizeText: "Size",
    with_coverText: "Included with the cover",
    yesOrNoText: ["Yes", "No"],
    amountText: "Amount",
    price_per_itemText: "Price per item",
    discountText: "Discount",
    sumText: "Sum",
    send_questionText: "Send Question",
    receive_pdfText: "PDF sketch",
    bigDescriptionOneProductMultipleChoices: "one product many possibilities",
    sendToUsYourLogo: "Send to us your logo",
    sendToUsSmallTextPart1: "WE WILL MAKE AN EXCELLENT",
    sendToUsSmallTextPart2: "FREE PROJECT FOR YOU"
  },
  fr: {
    productName: "Products",
    homeName: "Home page",
    productSpecificationText: "Product specification",
    dimensionsText: "DIMENSION",
    minOrderTex: "MINIMUM ORDER QUANTITY",
    infoMaterialText: "MATERIAL INFORMATION",
    infoOverprintText: "OVERPRINT INFORMATION",
    infoAboutFinishingText: "FINISHING INFORMATION",
    infoAboutPunchText: "PUNCH INFORMATION",
    additionalOptionText: "ADDITIONAL OPTIONS",
    additionalInfoText: "ADDITIONAL INFORMATION",
    patternText: "PATTERN",
    leadTimeText: "LEAD TIME",
    infoAboutShippingText: "LOGISTICS",
    priceShippingText: "SHIPPING COST",
    sizeText: "Size",
    with_coverText: "Included with the cover",
    yesOrNoText: ["Yes", "No"],
    amountText: "Amount",
    price_per_itemText: "Price per item",
    discountText: "Discount",
    sumText: "Sum",
    send_questionText: "Send Question",
    receive_pdfText: "PDF sketch",
    bigDescriptionOneProductMultipleChoices: "one product many possibilities",
    sendToUsYourLogo: "Send to us your logo",
    sendToUsSmallTextPart1: "WE WILL MAKE AN EXCELLENT",
    sendToUsSmallTextPart2: "FREE PROJECT FOR YOU"
  }
};
