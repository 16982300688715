import React from "react";
import { Carousel, Container } from "react-bootstrap";

const ProductPhotos = ({ photosList }) => {
  return (
    <Container>
      <Carousel>
        {photosList.map(photo => (
          <Carousel.Item key={photo}>
            <img className="d-block w-100" src={photo} alt="" />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default ProductPhotos;
