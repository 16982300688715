import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";
import {
  GlobalStateContext,
  GlobalDispatchContext
} from "../../context/GlobalContextProvider";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styles from "./Product.module.css";
import TrustedBy from "../AboutPage/TrustedBy";
import ProductPhotos from "./MainProductView/ProductPhotos/ProductPhotos";
import ProductPriceAndSize from "./MainProductView/ProductPriceAndSize/ProductPriceAndSize";
import MyTable from "./SecondPartProductView/Table/MyTable";
import Breadcrumbs from "./Breadcrumbs";
import staticText from "./productData.js";
import { getSizesString } from "../utils";

export const getPhoto768 = ph =>
  ph.mediaDetails.sizes.filter(s => s.width === "768")[0]?.sourceUrl;

const Product = ({ data }) => {
  const { language } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { productName, homeName } = staticText[language];
  const { id, title, content, acf } = data.wpPage;
  const { productSpecificationText } = staticText[language];
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <html lang={language} amp />
      </Helmet>
      <Container className={styles.main}>
        <Row>
          <Col>
            <Breadcrumbs
              // select={setSelected}
              // item={nameGlobalClickedProduct}
              globalId={id}
              globalName={acf.producttype}
              name={title}
              globalDispatchContext={GlobalDispatchContext}
              product={productName}
              home={homeName}
              language={language}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} />
          <Col xs={12} md={6}>
            <h3>{title}</h3>
          </Col>
        </Row>
        <Row>
          {acf.photos.length > 0 && (
            <Col xs={12} md={6}>
              <div className={styles.col_left}>
                <ProductPhotos
                  photosList={acf.photos.map(ph => {
                    return ph.sourceUrl;
                  })}
                />
              </div>
            </Col>
          )}
          {acf.dimensions && (
            <Col xs={12} md={6}>
              <div className={styles.col_right}>
                <ProductPriceAndSize
                  language={language}
                  sizeList={acf.dimensions}
                  price={"0"}
                  cover={false}
                  pdfFile={""}
                />
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <Container>
        {
          <Row>
            {acf.dimensions.length > 0 ? (
              <Col xs={12} md={4}>
                {productSpecificationText}
              </Col>
            ) : null}
            <Col xs={12} md={8}></Col>
          </Row>
        }
        <Row className={styles.specificationRow}>
          <Col xs={12} md={6}>
            {acf.dimensions.length > 0 ? (
              <MyTable
                language={language}
                tableData={{
                  dimensions: acf.dimensions.map((s, i) =>
                    getSizesString(s, i === acf.dimensions.length - 1)
                  ),
                  minimum_order_quantity: acf.minimumOrderQuantity,
                  material_information: acf.materialInformation,
                  print_information: acf.overprintInformation,
                  additional_options: acf.additionalOptions,
                  additional_information: acf.additionalInformation,
                  lead_time: acf.leadTime,
                  information_about_shipping_and_logistic: acf.logistics,
                  shipping_price: acf.shippingCost
                }}
              />
            ) : null}
          </Col>
          <Col xs={12} md={6}>
            {acf.shortDescription && (
              <p dangerouslySetInnerHTML={{ __html: acf.shortDescription }} />
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      </Container>
      <Container>
        <TrustedBy />
      </Container>
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query PageById($id: String) {
    wpPage(id: { eq: $id }) {
      authorId
      id
      title
      uri
      content
      slug
      language {
        slug
      }
      acf {
        dimensions {
          width
          height
          length
        }
        additionalInformation
        additionalOptions
        fieldGroupName
        leadTime
        logistics
        materialInformation
        minimumOrderQuantity
        overprintInformation
        shippingCost
        producttype
        photos {
          id
          link
          sourceUrl
          mediaDetails {
            sizes {
              sourceUrl
              width
            }
          }
        }
      }
    }
  }
`;
