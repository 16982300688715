import React, { useState, useContext } from "react";
import styles from "./Product.module.css";
import { Row, Col, Container } from "react-bootstrap";
import staticText from "../../productData.js";
import { langPrefix } from "../../../../shared/scripts";
import { Link } from "gatsby";
import { GlobalDispatchContext } from "../../../../context/GlobalContextProvider";
import { CHANGE_PAGE } from "../../../../types";
import { getSizes } from "../../../utils";

const MAX_VALUE_AMOUNT = 10000;
const MIN_VALUE_AMOUNT = 0;
const ITERATION_NUMBER_AMOUNT = 1000;

const checkValueAmount = (value, isAdding) => {
  var copyValue = isAdding
    ? value + ITERATION_NUMBER_AMOUNT
    : value - ITERATION_NUMBER_AMOUNT;

  if (copyValue > MAX_VALUE_AMOUNT || copyValue < MIN_VALUE_AMOUNT) {
    return value;
  } else {
    return copyValue;
  }
};

const createLink = photoName => {
  return require("../../../../toDownloadFile/" + photoName);
};

const ProductPriceAndSize = ({ language, sizeList, price, cover, pdfFile }) => {
  const {
    sizeText,
    with_coverText,
    yesOrNoText,
    send_questionText,
    receive_pdfText
  } = staticText[language];

  const [activeLi, setActiveLi] = useState(sizeList[0]);
  const [isYes, setIsYes] = useState(yesOrNoText[0]);
  const [amount, setAmount] = useState(MAX_VALUE_AMOUNT / 2);

  const dispatch = useContext(GlobalDispatchContext);

  const onPageChange = (e, page) => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };

  return (
    <div className={styles.main}>
      <Container>
        {sizeList.length !== 0 ? (
          <div>
            <Row>
              <div className={styles.sub_title}>{sizeText}</div>
            </Row>
            <Row className={styles.size}>
              <ul>
                {sizeList.map(i =>
                  i === activeLi ? (
                    <li
                      key={getSizes(i)}
                      className={styles.selected}
                      onClick={() => setActiveLi(i)}
                    >
                      {getSizes(i)}
                    </li>
                  ) : (
                    <li key={getSizes(i)} onClick={() => setActiveLi(i)}>
                      {getSizes(i)}
                    </li>
                  )
                )}
              </ul>
            </Row>
          </div>
        ) : null}
        {cover ? (
          <div>
            <Row>
              <div className={styles.sub_title}>{with_coverText}</div>
            </Row>
            <Row className={styles.cover}>
              <ul>
                {yesOrNoText.map(i =>
                  i === isYes ? (
                    <li
                      key={i}
                      className={styles.selected}
                      onClick={() => setIsYes(i)}
                    >
                      {i}
                    </li>
                  ) : (
                    <li key={i} onClick={() => setIsYes(i)}>
                      {i}
                    </li>
                  )
                )}
              </ul>
            </Row>
          </div>
        ) : null}
        <Row className={styles.buttonRow}>
          <Link
            className={styles.button}
            to={`${langPrefix(language)}contact/#form`}
            onClick={event => onPageChange(event, "contact")}
          >
            {send_questionText}
          </Link>
        </Row>
        {pdfFile.toString() === "" ? null : (
          <Row className={styles.pdfRow}>
            <Col className={styles.textPDF}>
              <a href={createLink(pdfFile)} download={pdfFile}>
                {receive_pdfText}
              </a>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ProductPriceAndSize;
