export default {
  "bakery-bags": {
    en: "Bakery bags",
    dk: "Bagerposer"
  },
  "block-bottom-sos-bags": {
    en: "Block bottom sos bags",
    dk: "Små klodsbundsposer"
  },
  "cake-boxes": {
    en: "Cake boxes",
    dk: "Kageæske"
  },
  "donut-boxes": {
    en: "Donut boxes",
    dk: "Æsker til donuts"
  },
  "paper-boxes": {
    en: "Takeaway boxes",
    dk: "Bokse og æsker"
  },
  "paper-napkins": {
    en: "Paper napkins with logo",
    dk: "Servietter med logo"
  },
  "paper-trays": {
    en: "Paper trays",
    dk: "Pølsebakker"
  },
  "pizza-boxes": {
    en: "Pizza boxes",
    dk: "Pizzabakker og æsker"
  },
  "smorrebrod-package": {
    en: "Boxes for open sandwich-smørrebrød",
    dk: "Smørrebrødsæsker"
  },
  "sticker-labels": {
    en: "Stickers and labels",
    dk: "Klistermærker og labels"
  },
  "sushi-boxes": {
    en: 'Sushi boxes "Box4Sushi"',
    dk: 'Sushi bokse "Box4Sushi"'
  },
  "takeaway-bags-for-gastronomy": {
    en: "Takeaway bags for gastronomy",
    dk: "Papirposer"
  },
  "wrapping-paper": {
    en: "Wrap papers and paper pockets",
    dk: "Sandwichpapir og burgerlommer"
  }
};
