import React, { useContext } from "react";
import styles from "./Breadcrumbs.module.css";
import { Link } from "gatsby";
import { langPrefix } from "../../../shared/scripts";
import { HOME_PAGE_CLICKED_PRODUCT } from "../../../types";
import data from "../../../shared/sideNavData";

const Breadcrumbs = ({
  globalId,
  globalName,
  name,
  globalDispatchContext,
  product,
  home,
  language
}) => {
  const dispatch = useContext(globalDispatchContext);

  const onSubProductPageChange = page => {
    dispatch({ type: HOME_PAGE_CLICKED_PRODUCT, payload: page });
  };
  const setDefaultLanguage = lang => {
    return lang === "dk" || lang === "da" ? "dk" : "en";
  };

  return (
    <ul className={styles.self}>
      <li>
        <Link to={`${langPrefix(language)}`}>{home}</Link>
      </li>
      <li>
        <Link to={`${langPrefix(language)}products`}>{product}</Link>
      </li>

      <li className={styles.active}>{name}</li>
    </ul>
  );
};

export default Breadcrumbs;
